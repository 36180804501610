.login-container[data-v-37dfd6fc] {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #eee;
}
.login-container .login-form[data-v-37dfd6fc] {
    position: absolute;
    left: 0;
    right: 0;
    max-width: 520px;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
    background: #fff;
}
.login-container .login-form .fa-icon[data-v-37dfd6fc] {
      width: 18px;
      height: 18px;
}
.login-container .title-container[data-v-37dfd6fc] {
    position: relative;
}
.login-container .title-container .logo[data-v-37dfd6fc] {
      height: 100px;
}
.login-container .title-container .title[data-v-37dfd6fc] {
      font-size: 26px;
      font-weight: 400;
      font-weight: bold;
      color: #333;
      margin: 0px auto 40px auto;
      text-align: center;
}
